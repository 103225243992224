import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";

import { useCallback, useContext, useEffect } from "react";

import Routes from "components/Routes";
import { SchoolContext } from "components/SchoolContext";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    font-family: "Lato", sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    color: ${({ theme }) => theme.colors.textPrimary};
    letter-spacing: 0.5px;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
`;

function App() {
  const { fetchSemesters, token } = useContext(EscolaLMSContext);
  const { setSemesterId } = useContext(SchoolContext);

  //Use this if academic years will be filtered by backend and available to change
  // const fetchInitialData = useCallback(async () => {
  //   const academicYearResponse = await fetchAcademicYears({ active: true });
  //   if (
  //     academicYearResponse?.success &&
  //     Array.isArray(academicYearResponse.data)
  //   ) {
  //     const firstAcademicYearId = academicYearResponse.data[0]?.id;

  //     if (!firstAcademicYearId) {
  //       console.error("No academic year found");
  //       return;
  //     }

  //     setAcademicYearId(firstAcademicYearId);

  //     const res = await fetchSemesters();

  //     if (res?.success && Array.isArray(res.data)) {
  //       const activeSemesters = res.data.filter((item) => item.is_active);
  //       if (activeSemesters.length === 0) {
  //         setSemesterId(null);
  //       } else {
  //         setSemesterId(activeSemesters[0]?.id);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchAcademicYears, fetchSemesters]);

  const fetchInitialData = useCallback(async () => {
    const res = await fetchSemesters();
    if (res?.success && Array.isArray(res.data)) {
      const activeSemesters = res.data.filter((item) => item.is_active);
      if (activeSemesters.length === 0) {
        setSemesterId(null);
      } else {
        setSemesterId(activeSemesters[0]?.id);
      }
    }
  }, [fetchSemesters, setSemesterId]);

  useEffect(() => {
    token && fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <GlobalStyles />
      <Routes />
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default App;
