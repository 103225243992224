import flow from "lodash.flow";

export const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName[0]}${lastName[0]}`;
};

export const getTopicType = (type: string) => type.split("\\")?.pop();

/* The markdown generator at multilines generates redundant `\` characters. This method removes them. */
const trimContentForMarkdown = (content = ""): string => {
  return content
    .replaceAll(/(\\\r\n|\\r\n|\\\n|\\n)/g, "\n") // escaped and non escaped new line
    .split("\n")
    .map((r) => {
      // if row is empty then do nothing
      if (!r.trim().length) return r;

      // remove all artifacts
      const n = r.replace(/\\+/g, "");

      // if, after deleting the artefacts, the row is empty, it was indeed an artefact and we remove it
      return !n.trim().length ? n : r;
    })
    .join("\n");
};

const fixInlineStylesSyntaxForMarkdown = (content = ""): string => {
  return !content.includes("style=”")
    ? content
    : content
        .split("style=”")
        .map((r) => r.replace("”", '"'))
        .join('style="');
};
export const fixContentForMarkdown = (content = ""): string => {
  if (content === null || content === "null") return "";
  return flow([
    trimContentForMarkdown,
    fixInlineStylesSyntaxForMarkdown,
    // fixUnderlineForMarkdown,
    // fixNoticeForMarkdown,
  ])(content);
};
let time = Date.now();
const usedIds: string[] = [];
export const getUniqueId = (uniqueName: string, twoIds = false) => {
  const newId = `${time}--${uniqueName}`;
  if (usedIds.filter((id) => id === newId).length > (twoIds ? 1 : 0)) {
    time = Date.now();
    usedIds.push(`${time}--${uniqueName}`);
    return `${time}--${uniqueName}`;
  }
  usedIds.push(newId);
  return newId;
};
