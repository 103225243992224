import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { AttendanceStatus } from "types/attendances.types";

import React, { useContext, useEffect, useMemo } from "react";

import Loader from "components/Loader";

import {
  CenteredContainer,
  Content,
  ContentColumn,
  Frequency,
  FrequencyTitle,
  Grade,
  GradeName,
  GradePercentage,
  InfoBar,
  List,
  NormalText,
} from "./styles";

type Props = {
  subject: API.GroupSubject;
};

const CollapseContent: React.FC<Props> = ({ subject }) => {
  const { attendances, fetchAttendances, fetchExams, exams } =
    useContext(EscolaLMSContext);

  useEffect(() => {
    fetchExams({
      group_id: subject.group_id,
      per_page: 999,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAttendances(subject.group_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentSubjectAttendance = useMemo(() => {
    return attendances?.byId?.[subject.group_id]?.value;
  }, [attendances, subject.group_id]);

  const presence = useMemo(() => {
    if (currentSubjectAttendance?.length === 0) return [];

    return currentSubjectAttendance?.filter(
      (attendance) =>
        attendance.attendances?.[0]?.value === AttendanceStatus.PRESENT
    );
  }, [currentSubjectAttendance]);

  const absence = useMemo(() => {
    if (currentSubjectAttendance?.length === 0) return [];

    return currentSubjectAttendance?.filter(
      (attendance) =>
        attendance.attendances?.[0]?.value === AttendanceStatus.ABSENT
    );
  }, [currentSubjectAttendance]);

  return (
    <Content data-testid="collapse-content">
      <ContentColumn>
        <InfoBar>Uzyskane oceny</InfoBar>
        {exams.byId?.[subject.group_id]?.loading ? (
          <CenteredContainer>
            <Loader />
          </CenteredContainer>
        ) : exams.byId?.[subject.group_id]?.value?.length === 0 ? (
          <CenteredContainer>Brak ocen cząstkowych</CenteredContainer>
        ) : (
          <List>
            {exams.byId?.[subject.group_id]?.value?.map((item) => (
              <Grade key={item.id}>
                <div>{new Date(item.passed_at).toLocaleDateString()}</div>
                <GradeName>{item.title}</GradeName>
                <GradePercentage $passed={item.results?.[0]?.result >= 50}>
                  {item.results?.[0]?.result}%
                </GradePercentage>
              </Grade>
            ))}
            {subject?.final_grades?.length > 0 && (
              <NormalText>Oceny końcowe:</NormalText>
            )}
            {subject.final_grades.map((item) => (
              <Grade key={item.id}>
                <div>{new Date(item.grade_date).toLocaleDateString()}</div>
                <GradeName>{item.grade_term?.name}</GradeName>
                <GradePercentage>{item.grade_name}</GradePercentage>
              </Grade>
            ))}
          </List>
        )}
      </ContentColumn>

      <ContentColumn>
        <InfoBar>Frekwencja</InfoBar>
        {attendances.byId?.[subject.group_id]?.loading ? (
          <CenteredContainer>
            <Loader />
          </CenteredContainer>
        ) : attendances?.byId?.[subject.group_id]?.value?.length === 0 ? (
          <CenteredContainer>Brak planu</CenteredContainer>
        ) : (
          <List>
            <Frequency>
              <FrequencyTitle>Obecności:</FrequencyTitle>
              <div>
                {presence?.length}/{currentSubjectAttendance?.length}
              </div>
            </Frequency>
            <Frequency>
              <FrequencyTitle>Nieobecności:</FrequencyTitle>
              <div>{absence?.length}</div>
            </Frequency>
          </List>
        )}
      </ContentColumn>
    </Content>
  );
};

export default CollapseContent;
