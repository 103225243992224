import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import React, { useCallback, useContext, useEffect, useMemo } from "react";

import CourseCard from "components/CourseCard";
import Layout from "components/Layout";
import routeRoutes from "components/Routes/routes";

import { getPreviousProgressedCourseProgressItemElement } from "utils/course";

const CoursesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CourseColumn = styled.div`
  padding: 0 15px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media ${device.mobile} {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const ELearningPage: React.FC = () => {
  const { fetchProgress, progress } = useContext(EscolaLMSContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCardClickFactory = useCallback(
    (progressItem: API.CourseProgressItem) => () => {
      const previousProgressedTopic =
        getPreviousProgressedCourseProgressItemElement(progressItem);
      const courseId = progressItem.course.id;

      if (!previousProgressedTopic) {
        navigate(`${routeRoutes.eLearning}/${courseId}`);
        return;
      }

      navigate(
        `${routeRoutes.eLearning}/${courseId}/${previousProgressedTopic.topic_id}`
      );
    },
    [navigate]
  );

  const progressMap = useMemo(() => {
    return (progress.value || []).reduce(
      (acc: object, curr: API.CourseProgressItem) => {
        return {
          ...acc,
          [curr.course.id ? curr.course.id : -1]: Math.round(
            ((curr.progress || []).reduce(
              (pAcc, pCurr) => (pCurr.status === 1 ? pAcc + 1 : pAcc),
              0
            ) /
              curr.progress.length) *
              100
          ),
        };
      },
      {}
    );
  }, [progress]);

  return (
    <Layout title="E-learning">
      <CoursesContainer data-testid="eLearning-page">
        {progress.value?.map((item) => (
          <CourseColumn key={item.course.id}>
            <CourseCard
              progress={
                item.course.id && progressMap[item.course.id]
                  ? progressMap[item.course.id]
                  : 0
              }
              course={item.course}
              onClick={onCardClickFactory(item)}
            />
          </CourseColumn>
        ))}
      </CoursesContainer>
    </Layout>
  );
};

export default ELearningPage;
