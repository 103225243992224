import ReactMarkdown from "react-markdown";
import styled, { createGlobalStyle } from "styled-components";

// import { FontSize, lightboxOverwriteMixin, textMixin } from '@/theme';
// import { ClickableWrapper } from '@/components/atoms';

export const StyledMarkdownRenderer = styled(ReactMarkdown)``;
//   $fontSize: FontSize;
// }>`
//   ${({ $fontSize }) =>
//     textMixin({
//       size: $fontSize,
//       family: 'secondary',
//       weight: 'medium',
//       color: 'neutral800',
//     })};
//   line-height: 1.6;

//   h1 {
//     ${textMixin({
//       size: 'xxl4',
//       family: 'secondary',
//       weight: 'bold',
//       color: 'neutral800',
//     })};
//   }

//   h2 {
//     ${textMixin({
//       size: 'xxl3',
//       family: 'secondary',
//       weight: 'bold',
//       color: 'neutral800',
//     })};
//   }

//   h3 {
//     ${textMixin({
//       size: 'xxl2',
//       family: 'secondary',
//       weight: 'bold',
//       color: 'neutral800',
//     })};
//   }

//   h4 {
//     ${textMixin({
//       size: 'xxl',
//       family: 'secondary',
//       weight: 'bold',
//       color: 'neutral800',
//     })};
//   }

//   h5 {
//     ${textMixin({
//       size: 'xl',
//       family: 'secondary',
//       weight: 'bold',
//       color: 'neutral800',
//     })};
//   }

//   h6 {
//     ${textMixin({
//       size: 'lg',
//       family: 'secondary',
//       weight: 'bold',
//       color: 'neutral800',
//     })};
//   }

//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     margin-bottom: 0.8em;
//   }

//   p,
//   ul,
//   ol,
//   div {
//     margin-bottom: 1em;
//   }

//   ul {
//     padding-inline-start: 29px;
//     list-style-image: url('/images/list-marker.svg');

//     li {
//       color: ${({ theme }) => theme.colors.neutral500};
//     }
//   }

//   ol {
//     padding-inline-start: 29px;

//     li {
//       color: ${({ theme }) => theme.colors.neutral500};
//     }
//   }

//   strong,
//   b {
//     font-weight: ${({ theme }) => theme.fontWeight.bold};
//   }

//   a {
//     color: ${({ theme }) => theme.colors.purple500};
//     font-weight: ${({ theme }) => theme.fontWeight.bold};

//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   .table-responsive {
//     td,
//     tr,
//     th {
//       border: 1px solid ${({ theme }) => theme.colors.neutral200};
//       padding: 5px 10px;
//     }
//     table {
//       border: 1px solid ${({ theme }) => theme.colors.neutral200};
//       border-collapse: collapse;
//     }
//   }
// `;

export const StyledClickableWrapper = styled.div`
  display: inline-block;
`;

export const LightboxOverride = createGlobalStyle`

`;
