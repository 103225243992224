import "katex/dist/katex.min.css";
import Lightbox from "react-18-image-lightbox";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import React, { useState } from "react";

import { fixContentForMarkdown } from "../../utils";
// import { FontSize } from '@/theme';
// import { PolymorphicLink, PolymorphicLinkElement } from '@/components/atoms';
import {
  LightboxOverride,
  StyledClickableWrapper,
  StyledMarkdownRenderer,
} from "./styles";

const MarkdownImage: React.ComponentType<
  React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement>
> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openLightbox = () => setIsOpen(true);

  return (
    <StyledClickableWrapper as="span" onClick={openLightbox}>
      <picture>
        <img src={props.src} alt={props.alt} style={{ cursor: "pointer" }} />
      </picture>
      {isOpen && props.src && (
        <>
          <LightboxOverride />
          <Lightbox
            mainSrc={props.src}
            onCloseRequest={() => setIsOpen(false)}
          />
        </>
      )}
    </StyledClickableWrapper>
  );
};

const MarkdownTable: React.ComponentType<
  React.TableHTMLAttributes<HTMLTableElement>
> = (props) => (
  <div className="table-responsive">
    <table className={`table ${props.className ?? ""}`} {...props}>
      {props.children}
    </table>
  </div>
);

interface MarkdownRendererProps
  extends Omit<
    ReactMarkdownOptions,
    "linkTarget" | "rehypePlugins" | "remarkPlugins" | "components"
  > {
  fontSize?: any;
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  fontSize = "md",
  children,

  ...props
}) => (
  <StyledMarkdownRenderer
    // $fontSize={fontSize}
    linkTarget="_blank"
    rehypePlugins={[rehypeRaw, rehypeKatex]}
    remarkPlugins={[remarkGfm, remarkMath]}
    components={{
      img: (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
        <MarkdownImage {...props} />
      ),
      table: (props: React.TableHTMLAttributes<HTMLTableElement>) => (
        <MarkdownTable {...props} />
      ),
      // a: (props) => <PolymorphicLink Element={Link} {...props} />,
    }}
    {...props}
  >
    {fixContentForMarkdown(children)}
  </StyledMarkdownRenderer>
);
