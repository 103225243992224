import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import React, { useEffect, useRef, useState } from "react";

// import Footer from "components/Footer";
import Header from "components/Header";
import Sidebar from "components/Sidebar";

import useMediaQuery from "hooks/UseMediaQuery";

type Props = {
  title?: string;
};

const MainLayout = styled.div`
  display: flex;
  height: 100vh;
  background: ${({ theme }) => theme.colors.primaryBackground};
`;

const ChildrenWrapper = styled.div<{
  $headerHeight: number;
  $footerHeight?: number;
}>`
  width: 100%;
  height: calc(100% - ${({ $headerHeight }) => $headerHeight}px);
  /* With footer */
  /* height: calc(
    100% -
      ${({ $headerHeight, $footerHeight }) =>
    $footerHeight && $headerHeight + $footerHeight + 30}px
  ); */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 28px 31px;

  @media ${device.mobile} {
    padding: 20px 17px;
  }
`;

const MainContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  margin-bottom: 25px;
`;

function useHeight() {
  const [headerHeight, setHeaderHeight] = useState(75);
  // const [footerHeight, setFooterHeight] = useState(90);
  const headerRef = useRef<HTMLDivElement>(null);
  // const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
    // if (footerRef.current) {
    //   setFooterHeight(footerRef.current.offsetHeight);
    // }
  }, []);

  // return { headerRef, headerHeight, footerRef, footerHeight };
  return { headerRef, headerHeight };
}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  const { headerRef, headerHeight } = useHeight();
  // const { footerRef, footerHeight } = useHeight();

  const tabletView = useMediaQuery("tablet");
  return (
    <MainLayout data-testid="layout">
      {!tabletView && <Sidebar />}
      <MainContent>
        <Header ref={headerRef} />
        <ChildrenWrapper
          $headerHeight={headerHeight}
          // $footerHeight={footerHeight}
        >
          {title && <Title>{title}</Title>}
          {children}
        </ChildrenWrapper>
      </MainContent>
      {/* Uncomment when footer is needed */}
      {/* <Footer ref={footerRef} /> */}
    </MainLayout>
  );
};

export default Layout;
