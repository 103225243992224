import { styled } from "styled-components";
import { device } from "theme/breakpoints";
import { getStyleBasedOnTheme } from "theme/utils";

export const Content = styled.div`
  height: auto;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  overflow: hidden;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
  }
`;

export const InfoBar = styled.div`
  background-color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: "#f2fcf8",
      contrast: theme.colors.gray,
    })};
  width: 100%;
  padding: 13px 20px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 11px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const ContentColumn = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  @media ${device.tablet} {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const Grade = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  width: 100%;
`;

export const GradeName = styled.div`
  margin-left: 25px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  flex: 0 0 35%;
  max-width: 35%;
`;

export const NormalText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 20px 0 0 0;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding: 0 20px;
  width: 100%;
`;

export const GradePercentage = styled.div<{ $passed?: boolean }>`
  color: ${({ theme, $passed }) =>
    getStyleBasedOnTheme(theme, {
      base: $passed
        ? theme.colors.green
        : $passed === false
        ? theme.colors.red
        : theme.colors.black,
      contrast: theme.colors.primary,
    })};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  @media ${device.tablet} {
    margin: 0 0 0 auto;
  }
`;

export const Frequency = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  gap: 55px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const FrequencyTitle = styled.div`
  min-width: 90px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
