import { API } from "@escolalms/sdk/lib";

import { FC } from "react";

import Icon from "components/Icon";
import ProgressBar from "components/ProgressBar";

import placeholder from "./placeholder.png";
import {
  Card,
  CardButton,
  CardImage,
  CardImageWrapper,
  Content,
  DisabledIcon,
  DisabledText,
  Teacher,
  Title,
} from "./styles";

type Props = {
  course: API.Course;
  onClick: () => void;
  progress: number;
};
const CourseCard: FC<Props> = ({ course, onClick, progress }) => {
  const checkCourseActive = (date?: string) => {
    if (!date) {
      return true;
    }

    const now = new Date();
    const activeTo = new Date(date);

    return now < activeTo;
  };

  return (
    <Card
      disabled={!checkCourseActive(course.active_to)}
      onClick={onClick}
      data-testid="course-card"
    >
      <CardButton>
        <CardImageWrapper>
          <CardImage src={course.image_url || placeholder} alt={course.title} />
          {!checkCourseActive(course.active_to) && (
            <DisabledIcon>
              <Icon name="lock" />
            </DisabledIcon>
          )}
        </CardImageWrapper>
        <Content>
          <Title>{course.title}</Title>

          {course.authors?.[0] && (
            <Teacher>{`${course.authors[0].first_name} ${course.authors[0].last_name}`}</Teacher>
          )}

          {checkCourseActive(course.active_to) ? (
            <>
              {progress > 0 && <ProgressBar progress={progress} />}
              {/* <DateComponent>ost. {course.date}</DateComponent> */}
            </>
          ) : (
            <DisabledText>Tymczasowo nieaktywne</DisabledText>
          )}
        </Content>
      </CardButton>
    </Card>
  );
};

export default CourseCard;
