import { API } from "@escolalms/sdk/lib";
import { useNavigate } from "react-router-dom";

import React, { useCallback, useState } from "react";

import { Button } from "components/Button";
import routeRoutes from "components/Routes/routes";

import useMediaQuery from "hooks/UseMediaQuery";

import { useCourse } from "../Context";
import CourseAgenda from "../CourseAgenda/CourseAgenda";
import { StyledSidebar } from "./styles";

export const CourseSidebar: React.FC<{
  onCourseFinish: () => void;
}> = ({ onCourseFinish }) => {
  const [agendaVisible, setAgendaVisible] = useState(false);
  const navigate = useNavigate();

  const mobileView = useMediaQuery("mobile");
  const { currentCourseProgram, nextTopic } = useCourse();

  const onTopicClick = useCallback(
    (topic: API.Topic) => {
      navigate(
        `${routeRoutes.eLearning}/${currentCourseProgram?.id}/${topic.id}`
      );

      setAgendaVisible(false);
    },
    [currentCourseProgram?.id, navigate]
  );

  const onNextTopicClick = useCallback(() => {
    if (nextTopic?.id === undefined) return;

    navigate(
      `${routeRoutes.eLearning}/${currentCourseProgram?.id}/${nextTopic.id}`
    );
    setAgendaVisible(false);
  }, [currentCourseProgram?.id, navigate, nextTopic?.id]);

  return (
    <>
      {mobileView && (
        <Button
          title="Pokaż agendę"
          variant="secondary"
          onClick={() => setAgendaVisible(true)}
        />
      )}
      <StyledSidebar
        $agendaVisable={agendaVisible}
        data-testid="course-sidebar"
      >
        <div>
          {mobileView && (
            <Button
              style={{
                marginLeft: 20,
                marginBottom: 20,
              }}
              title="Ukryj agendę"
              variant="secondary"
              onClick={() => setAgendaVisible(false)}
            >
              &#10005;
            </Button>
          )}
          <CourseAgenda
            onNextTopicClick={onNextTopicClick}
            mobile={mobileView}
            onTopicClick={onTopicClick}
            onCourseFinished={onCourseFinish}
          />
        </div>
      </StyledSidebar>
    </>
  );
};

export default CourseSidebar;
