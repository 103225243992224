import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { CourseProgressItemElementStatus } from "@escolalms/sdk/lib/types/api";
import { Col, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import React, { useContext, useEffect, useState } from "react";

import { Button } from "components/Button";
import { MarkdownRenderer } from "components/MarkdownRenderer";
import routeRoutes from "components/Routes/routes";

import CourseProgramContent from "../Course/CourseProgramContent";
import CourseSidebar from "../Course/CourseSidebar";
import { useCourse } from "./Context";
import CourseDownloads from "./CourseDownloads";

const StyledCourse = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 54px);
`;

const CourseDescription = styled.div`
  p,
  ul,
  li,
  a {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const CourseTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const CourseContent = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid ${({ theme }) => theme.colors.gray};

    td {
      padding: 10px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
    }

    tr {
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.colors.border};
      }
    }

    th {
      padding: 10px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }

  blockquote {
    border-left: 5px solid ${({ theme }) => theme.colors.primary};
    padding: 5px 5px 5px 5px;
    margin-left: 20px;
    font-style: italic;
    background-color: ${({ theme }) => theme.colors.border};
  }
`;

const CourseSummary = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding-top: 20px;
`;

export const CourseProgramLessons: React.FC = () => {
  const {
    isCourseFinished,
    currentTopic,
    currentLesson,
    flatTopics,
    currentCourseProgram,
  } = useCourse();

  const { sendProgress } = useContext(EscolaLMSContext);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const navigate = useNavigate();

  // scroll to top on every navigate
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentLesson?.id, currentTopic?.id]);

  const columnWidth = currentLesson?.summary && currentTopic?.summary ? 6 : 12;

  // development thing
  //@ts-ignore
  window.resetProgress = () => {
    sendProgress(
      currentCourseProgram?.id!,
      (flatTopics ?? []).map((topic) => ({
        topic_id: Number(topic?.id),
        status: CourseProgressItemElementStatus.INCOMPLETE,
      }))
    );
  };

  useEffect(() => {
    if (isCourseFinished) {
      setShowFinishModal(isCourseFinished);
    }
  }, [isCourseFinished]);

  if (!currentCourseProgram) {
    return <div>Brak programu</div>;
  }

  if (!currentTopic) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 40,
        }}
      >
        <h2>Brak tematu</h2>
        <Button
          title="Moje kursy"
          variant="secondary"
          onClick={() => navigate(routeRoutes.eLearning)}
        ></Button>
      </div>
    );
  }

  return (
    <StyledCourse data-testid="course-lessons">
      <div>
        <CourseTitle>Lekcja: {currentTopic.title}</CourseTitle>
        <Row>
          <Col lg={9}>
            {showFinishModal && (
              <div className="course-program-finish-modal">
                <p className="course-program-finish-modal__title">
                  Ukończyłeś już ten kurs
                </p>

                <div
                  className="course-program-finish-modal__buttons"
                  style={{
                    marginTop: 10,
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    title="Moje kursy"
                    variant="secondary"
                    onClick={() => navigate(routeRoutes.eLearning)}
                  />
                  <Button
                    title="Przejdź do lekcji"
                    onClick={() => setShowFinishModal(false)}
                  />
                </div>
              </div>
            )}
            {!showFinishModal && (
              <CourseContent>
                <div className="course-program-player-content">
                  {currentTopic?.introduction && (
                    <MarkdownRenderer>
                      {currentTopic.introduction}
                    </MarkdownRenderer>
                  )}
                  <div
                    className="course-program-player-content__wrapper"
                    style={currentTopic?.json?.wrapperStyle ?? {}}
                  >
                    <CourseProgramContent key={currentTopic?.id} />
                  </div>
                </div>
                <CourseDescription
                  className="course-program-content__container"
                  style={{
                    margin: "50px 0",
                  }}
                >
                  <Row>
                    {currentLesson?.summary && (
                      <Col sm={12} md={columnWidth} lg={columnWidth}>
                        <CourseSummary>
                          <MarkdownRenderer>
                            {currentLesson.summary}
                          </MarkdownRenderer>
                        </CourseSummary>
                      </Col>
                    )}
                    {currentTopic?.summary && (
                      <Col sm={12} md={columnWidth} lg={columnWidth}>
                        <CourseSummary>
                          <MarkdownRenderer>
                            {currentTopic?.summary}
                          </MarkdownRenderer>

                          {currentTopic?.resources?.length && (
                            <CourseDownloads
                              resources={currentTopic.resources}
                              subtitle={currentTopic.introduction || ""}
                            />
                          )}
                        </CourseSummary>
                      </Col>
                    )}
                  </Row>
                </CourseDescription>
              </CourseContent>
            )}
          </Col>
          <Col lg={3}>
            <CourseSidebar onCourseFinish={() => setShowFinishModal(true)} />
          </Col>
        </Row>
      </div>
    </StyledCourse>
  );
};

export default CourseProgramLessons;
