import { useTranslation } from "react-i18next";
// TODO CHANGE VERSION OF REACT-PDF TO NEWEST
import { Document, Page, PageProps, pdfjs } from "react-pdf";
import styled from "styled-components";

import React, { useEffect, useState } from "react";

import { Button } from "components/Button";

interface PdfPlayerProps {
  url: string;
  documentConfig?: Omit<React.ComponentProps<typeof Document>, "file">;
  pageConfig?: PageProps;
  onLoad?: () => void;
  onTopicEnd?: () => void;
}

const StyledWrapper = styled("div")`
  display: inline-block;

  .pagination-area {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
`;

export const PdfPlayer: React.FunctionComponent<PdfPlayerProps> = ({
  url,
  onLoad,
  onTopicEnd,
  documentConfig = {},
  pageConfig = {},
}): React.ReactElement => {
  const [allPages, setAllPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [endActionFired, setEndActionFired] = useState(false);
  const { t } = useTranslation();

  const handleNextPageClick = () => {
    if (onTopicEnd && allPages && !(allPages > currentPage)) {
      setEndActionFired(true);
      onTopicEnd();
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (currentPage === allPages) {
      onLoad?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, currentPage]);

  if (!url) {
    return <p>Nie znaleziono pliku .pdf</p>;
  }

  return (
    <StyledWrapper className="wellms-component" data-testid="pdf-player">
      {isMounted && url && (
        <Document
          loading={t("Loading")}
          onLoadSuccess={({ numPages }) => setAllPages(numPages)}
          file={url}
          {...documentConfig}
        >
          <Page
            pageNumber={currentPage}
            renderAnnotationLayer={false}
            {...pageConfig}
          />
        </Document>
      )}

      {allPages && allPages > 1 && (
        <div className="pagination-area">
          <p>
            <strong>{currentPage}</strong> strona z <strong>{allPages}</strong>
          </p>
          <div>
            <Button
              title="Poprzednia strona"
              variant="secondary"
              disabled={!(currentPage > 1)}
              className="nav-btn-modal"
              onClick={() => setCurrentPage(currentPage - 1)}
            ></Button>
            <Button
              title="Następna strona"
              style={{ marginLeft: "10px" }}
              variant="secondary"
              disabled={onTopicEnd ? endActionFired : !(allPages > currentPage)}
              className="nav-btn-modal"
              onClick={handleNextPageClick}
            ></Button>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

export default PdfPlayer;
