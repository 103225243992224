import { API } from "@escolalms/sdk/lib";
import styled from "styled-components";
import { CourseAgendaSharedComponentProps } from "types/common.types";

import React, { useCallback } from "react";

import { Button } from "components/Button";
import { Row } from "components/Row";

import { useCourse } from "../../Context";
import { CurrentIcon, FinishedIcon, PendingIcon } from "./Icons";

const StyledRow = styled(Row)`
  cursor: pointer;
  & p {
    margin: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
  }
`;
const ListItem = styled.li`
  margin-bottom: 15px;
  list-style: none;

  button {
    margin-top: 10px;
    max-width: unset !important;
    width: 100%;
  }
`;

export interface CourseAgendaTopicProps
  extends CourseAgendaSharedComponentProps {
  index: number;
  topic: API.Topic;
  clickable: boolean;
  mode: "pending" | "current" | "finished";
}
const TopicIcon: React.FC<{ mode: CourseAgendaTopicProps["mode"] }> = ({
  mode,
}) => {
  switch (mode) {
    case "current":
      return <CurrentIcon />;
    case "finished":
      return <FinishedIcon />;
    case "pending":
      return <PendingIcon />;
  }
};

const CourseAgendaTopic: React.FC<CourseAgendaTopicProps> = ({
  index,
  topic,
  mode,
  clickable,
  onCourseFinished,
  onTopicClick,
  onNextTopicClick,
}) => {
  const {
    isNextTopicButtonDisabled,
    finishedTopicsIds,
    flatTopics,
    completeCurrentTopic,
  } = useCourse();

  const onClick = useCallback(() => {
    if (mode !== "current") {
      onTopicClick(topic);
    }
  }, [mode, onTopicClick, topic]);

  const isTopicFinished = finishedTopicsIds?.includes(topic.id);
  const isLastTopic = flatTopics?.[flatTopics?.length - 1]?.id === topic.id;

  return (
    <ListItem data-testid="agenda-topic">
      <StyledRow
        $alignItems="center"
        $gap={6}
        tabIndex={clickable ? 0 : -1}
        onClick={() => clickable && onClick()}
        onKeyDown={(e: React.KeyboardEvent) =>
          clickable && e.key === "Enter" && onClick()
        }
        role="button"
      >
        <TopicIcon mode={mode} />
        <strong>{index}. </strong>
        <p>{topic.title}</p>
      </StyledRow>

      {mode === "current" && !isTopicFinished && isLastTopic && (
        <Button
          title="Ukończ"
          variant="secondary"
          disabled={isNextTopicButtonDisabled}
          onClick={() => {
            if (!clickable) return;
            completeCurrentTopic?.();
            onCourseFinished();
          }}
        />
      )}

      {mode === "current" && !isTopicFinished && !isLastTopic && (
        <Button
          title="Zaznacz jako wykonane"
          variant="secondary"
          disabled={isNextTopicButtonDisabled}
          onClick={completeCurrentTopic}
        />
      )}

      {mode === "current" && isTopicFinished && !isLastTopic && (
        <Button
          title="Następna lekcja"
          variant="secondary"
          onClick={onNextTopicClick}
        />
      )}
    </ListItem>
  );
};

export default CourseAgendaTopic;
